import LocalStorageUtil, {
  StorageKeyInfo,
} from "@reservauto/react-shared/localStorage/LocalStorageUtil";
import AuthenticatedServiceBase from "@reservauto/react-shared/services/AuthenticatedServiceBase";
import { FetchPromise } from "@reservauto/react-shared/services/ServiceBase";
import branchStore from "../../shared/stores/branchStore";

const iframeIsAuthenticatedStoreKey: StorageKeyInfo<boolean> = {
  key: "IframeIsAuthenticated",
  pathIndependant: true,
};

export interface LegacyLoginRespose {
  d?: {
    Success: boolean;
  };
}

export interface LegacyLogoutRespose {
  d?: {
    Success: boolean;
  };
}

export function expireLegacySession(): void {
  new LegacyAuthenticationService().loginExpired();
}

export function loginToLegacy(force?: boolean): FetchPromise<void> {
  const legacyAuthService = new LegacyAuthenticationService();

  if (force) {
    legacyAuthService.loginExpired();
  }

  const legacyRequest = legacyAuthService.login();

  const promise = new FetchPromise<void>(async (resolve, reject) => {
    try {
      const result = await legacyRequest;

      if (!result.d?.Success) {
        reject(new Error("Legacy signin failed"));
      } else {
        resolve();
      }
    } catch (ex) {
      reject(ex);
    }
  });

  promise.abort = (): void => legacyRequest.abort();

  return promise;
}

export function logoutOfLegacy(): FetchPromise<void> {
  const legacyAuthService = new LegacyAuthenticationService();

  const legacyRequest = legacyAuthService.logout();

  const promise = new FetchPromise<void>(async (resolve, reject) => {
    try {
      const result = await legacyRequest;

      if (!result.d?.Success) {
        reject(new Error("Legacy signout failed"));
      } else {
        resolve();
      }
    } catch (ex) {
      reject(ex);
    }
  });

  promise.abort = (): void => legacyRequest.abort();

  return promise;
}

function getIsAuthenticated(): boolean {
  return LocalStorageUtil.get(iframeIsAuthenticatedStoreKey, false);
}

export default class LegacyAuthenticationService extends AuthenticatedServiceBase {
  protected baseUrl = "WCF/Reservauto/Authentication/AuthenticationService.svc";

  protected get rootUrl(): string {
    return branchStore.get().reservautoLegacyUri!;
  }

  public login(): FetchPromise<LegacyLoginRespose> {
    if (getIsAuthenticated()) {
      return new FetchPromise((resolve) => resolve({ d: { Success: true } }));
    }

    return this.authorizedPost<LegacyLoginRespose, unknown>({
      isRetrySafe: true,
      url: "CustomerSpaceLogin",
    }).then((result) => {
      if (result.d?.Success) {
        LocalStorageUtil.set(iframeIsAuthenticatedStoreKey, true);
      }
      return result;
    });
  }

  public loginExpired(): void {
    LocalStorageUtil.set(iframeIsAuthenticatedStoreKey, false);
  }

  public logout(): FetchPromise<LegacyLogoutRespose> {
    const isAuthenticated = getIsAuthenticated();
    if (isAuthenticated) {
      LocalStorageUtil.set(iframeIsAuthenticatedStoreKey, false);

      return this.authorizedPost({ isRetrySafe: true, url: "Logout" });
    }
    return new FetchPromise((resolve) => resolve({ d: { Success: true } }));
  }
}
