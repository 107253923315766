import LocalStorageUtil, {
  StorageKeyInfo,
} from "@reservauto/react-shared/localStorage/LocalStorageUtil";
import Logging from "@reservauto/react-shared/Logging";
import { FetchPromise } from "@reservauto/react-shared/services/ServiceBase";
import baseBranchStore from "@reservauto/react-shared/stores/baseBranchStore";
import languageStore from "@reservauto/react-shared/stores/languageStore";
import StateStoreBase from "@reservauto/react-shared/stores/StateStoreBase";
import { toZonedTime } from "date-fns-tz";
import {
  BranchAvailableCityDTO,
  BranchAvailableCityListDTO,
  BranchAvailableLanguageDTO,
  BranchAvailableLanguageListDTO,
  BranchSettingListDTO,
} from "../../areas/branch/dto";
import BranchSettingType from "../../areas/branch/enums/BranchSettingType";
import {
  AvailableBranchDTO,
  AvailableBranchListDTO,
} from "../../areas/general/dto";
import appSettings from "../appSettings";

export interface Branch {
  associatedBranches?: AvailableBranchDTO[];
  browserTabName: string;
  cities?: BranchAvailableCityDTO[];
  id: number;
  isPartner: boolean;
  languages?: BranchAvailableLanguageDTO[];
  partnerNames?: string[];
  reservautoLegacyUri?: string;
  settings?: Settings;
  urlPart: string;
}

interface Settings {
  currency: string;
  timeZone: string;
}

function getAssociatedBranchesStorageKey(): StorageKeyInfo<
  AvailableBranchDTO[] | null
> {
  return {
    key: `AssociatedBranches_${branchStore.get().id.toString()}_${languageStore
      .get()
      .databaseId.toString()}`,
    pathIndependant: true,
    ttl: { days: 5 },
    userIndependant: true,
  };
}

function getCitiesStorageKey(): StorageKeyInfo<
  BranchAvailableCityDTO[] | null
> {
  return {
    key: `BranchCities_${branchStore.get().id.toString()}_${languageStore
      .get()
      .databaseId.toString()}`,
    pathIndependant: true,
    ttl: { days: 5 },
    userIndependant: true,
  };
}

function getLanguagesStorageKey(): StorageKeyInfo<
  BranchAvailableLanguageDTO[] | null
> {
  return {
    key: `BranchLanguages_${branchStore.get().id.toString()}`,
    pathIndependant: true,
    ttl: { days: 5 },
    userIndependant: true,
  };
}

const fallbackBranchId = 1;

export class BranchStore extends StateStoreBase<Branch> {
  private currentBranch: Branch = window.branches.all.find(
    (b) => b.id === fallbackBranchId,
  )!;

  public correctLocale(locale: string | undefined): string {
    let twoLetterCode = locale?.toLowerCase().substring(0, 2) ?? "fr";

    const branchLanguages = this.currentBranch.languages ?? [];
    const isValidBranchLanguage = branchLanguages.some(
      (l) => l.languageIso639_1TwoLettersCode === twoLetterCode,
    );

    if (!isValidBranchLanguage) {
      void Logging.warning(
        `Unknown language ${twoLetterCode} for branch ${this.currentBranch.id.toString()}`,
      );

      twoLetterCode = branchLanguages[0].languageIso639_1TwoLettersCode!;
    }

    const language = languageStore
      .getAll()
      .find((l) => l.twoLetterCode === twoLetterCode);

    if (!language) {
      void Logging.warning(`Unknown language ${twoLetterCode}`);
    }

    return language ? language.localeId : "fr-ca";
  }

  public get(): Branch {
    return this.currentBranch;
  }

  public getAll(): Branch[] {
    return window.branches.all;
  }

  public getCurrentTimeInBranchTimeZone(): Date {
    return toZonedTime(
      // eslint-disable-next-line no-restricted-syntax
      new Date().getTime(),
      this.currentBranch.settings?.timeZone ?? "America/Toronto",
    );
  }

  public getTimezone(): string {
    return this.currentBranch.settings?.timeZone ?? "America/Toronto";
  }

  public populateAssociated(
    getAssociated: (branchId: number) => FetchPromise<AvailableBranchListDTO>,
  ): FetchPromise<AvailableBranchDTO[]> {
    const storageKey = getAssociatedBranchesStorageKey();
    const branches = LocalStorageUtil.get(storageKey, null);

    let promise: FetchPromise<AvailableBranchDTO[]>;
    if (branches) {
      promise = new FetchPromise((resolve) => {
        resolve(branches);
      });
    } else {
      promise = getAssociated(this.currentBranch.id).then((result) => {
        if (result.branches) {
          LocalStorageUtil.set(storageKey, result.branches);
        }

        return result.branches ?? [];
      });
    }

    void promise.then((result) => {
      this.currentBranch = {
        ...this.currentBranch,
        associatedBranches: result.length > 0 ? result : undefined,
      };
      this.notifySubscribers();
    });

    return promise;
  }

  public async populateCities(
    getCities: (branchId: number) => Promise<BranchAvailableCityListDTO>,
  ): Promise<void> {
    const storageKey = getCitiesStorageKey();
    let cities = LocalStorageUtil.get(storageKey, null);

    if (!cities) {
      const result = await getCities(this.currentBranch.id);
      cities =
        result.cities?.filter((c) => c.branchId === this.currentBranch.id) ??
        [];
      LocalStorageUtil.set(storageKey, cities);
    }

    this.currentBranch = {
      ...this.currentBranch,
      cities: cities,
    };
    this.notifySubscribers();
  }

  public async populateLanguages(
    getLanguages: (branchId: number) => Promise<BranchAvailableLanguageListDTO>,
  ): Promise<void> {
    const storageKey = getLanguagesStorageKey();
    let languages = LocalStorageUtil.get(storageKey, null);

    if (!languages) {
      const result = await getLanguages(this.currentBranch.id);
      languages = this.convertLanguages(result);
      LocalStorageUtil.set(storageKey, languages);
    }

    this.currentBranch = {
      ...this.currentBranch,
      languages: languages,
    };
    this.notifySubscribers();
  }

  public async populateSettings(
    getSetting: (branchId: number) => Promise<BranchSettingListDTO>,
  ): Promise<void> {
    try {
      const result = await getSetting(this.currentBranch.id);

      const settings = this.convertSettings(result);
      this.currentBranch = {
        ...this.currentBranch,
        settings: settings,
      };

      baseBranchStore.populate({ ...this.currentBranch, ...settings });
    } catch (error) {
      void Logging.error(error);

      this.currentBranch = {
        ...this.currentBranch,
        settings: { currency: "CAD", timeZone: "America/Toronto" },
      };
    }
    this.notifySubscribers();
  }

  public setById(branchId: number): Branch {
    let branch = window.branches.all.find((b) => b.id === branchId);
    if (!branch) {
      void Logging.warning(`Unknown branch id ${branchId.toString()}`);
      branch = window.branches.all.find((b) => b.id === fallbackBranchId)!;
    }

    let legacyUrl =
      appSettings.ReservautoLegacyUris["Branch_" + branchId.toString()];
    if (!legacyUrl) {
      legacyUrl = appSettings.ReservautoLegacyUris.Default;
    }

    this.currentBranch = {
      ...branch,
      reservautoLegacyUri: legacyUrl,
    };
    baseBranchStore.populate({
      ...baseBranchStore.get(),
      ...this.currentBranch,
    });

    this.notifySubscribers();

    return this.currentBranch;
  }

  private convertLanguages(
    languages: BranchAvailableLanguageListDTO,
  ): BranchAvailableLanguageDTO[] {
    for (const language of languages.branchLanguages!) {
      language.languageIso639_1TwoLettersCode =
        language.languageIso639_1TwoLettersCode!.toLowerCase();
    }
    return languages.branchLanguages!;
  }

  private convertSettings(settings: BranchSettingListDTO): Settings {
    function findSetting(type: BranchSettingType): string | undefined {
      return (
        settings.branchSettings?.find(
          (s) => s.branchSettingId === (type as number),
        )?.value ?? undefined
      );
    }

    return {
      currency: findSetting(BranchSettingType.DefaultCurrency)!,
      timeZone: findSetting(BranchSettingType.Timezone)!,
    };
  }
}

const branchStore = new BranchStore();
export default branchStore;
